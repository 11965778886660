// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-barometres-js": () => import("./../src/pages/barometres.js" /* webpackChunkName: "component---src-pages-barometres-js" */),
  "component---src-pages-dynamiques-des-territoires-js": () => import("./../src/pages/dynamiques-des-territoires.js" /* webpackChunkName: "component---src-pages-dynamiques-des-territoires-js" */),
  "component---src-pages-fragilite-acces-numerique-js": () => import("./../src/pages/fragilite-acces-numerique.js" /* webpackChunkName: "component---src-pages-fragilite-acces-numerique-js" */),
  "component---src-pages-fragilite-des-enfants-js": () => import("./../src/pages/fragilite-des-enfants.js" /* webpackChunkName: "component---src-pages-fragilite-des-enfants-js" */),
  "component---src-pages-fragilite-des-personnes-agees-js": () => import("./../src/pages/fragilite-des-personnes-agees.js" /* webpackChunkName: "component---src-pages-fragilite-des-personnes-agees-js" */),
  "component---src-pages-fragilite-economique-js": () => import("./../src/pages/fragilite-economique.js" /* webpackChunkName: "component---src-pages-fragilite-economique-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-niveaux-vie-js": () => import("./../src/pages/niveaux-vie.js" /* webpackChunkName: "component---src-pages-niveaux-vie-js" */),
  "component---src-pages-sante-js": () => import("./../src/pages/sante.js" /* webpackChunkName: "component---src-pages-sante-js" */)
}

